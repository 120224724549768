import React from 'react';
import MaintenancePage from './MaintenancePage';
import WarrantyPage from './WarrantyPage';
import TomTomPage from './TomTomPage';
import ServicePlansPage from './ServicePlansPage';
import BodyshopsPage from './BodyshopsPage';
import RoadsideAssistance from './RoadsideAssistancePage';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Navigation from './Navigation';

const AfterSalesComponent = props => {

    const section = () => {
        if (props.match.path === '/maintenance') return <MaintenancePage />

        if (props.match.path === '/warranty') return <WarrantyPage />

        if (props.match.path === '/tomtom-live') return <TomTomPage />

        if (props.match.path === '/service-plans') return <ServicePlansPage />

        if (props.match.path === '/bodyshops') return <BodyshopsPage />

        if (props.match.path === '/roadside-assistance') return <RoadsideAssistance />

    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item sm={12} md={8}>
                    {section()}
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Navigation />
                </Grid>
            </Grid>

        </Container>
    )

}

export default AfterSalesComponent;