import {useState, useEffect} from 'react';
import axios from 'axios';
import { BASE_URI, PAGE_SIZE, DEALERID, MASTER_DEALERID } from '../shared/Constants'

export default function DealsFilter(pageNumber, sortDirection) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [deals, setDeals] = useState([])
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    setDeals([])
  }, [ sortDirection])

  useEffect(() => {
    let cancel
    setLoading(true)
    setError(false)
    const params = {
      pageNumber,
      pageSize: PAGE_SIZE,
      status: 'active',
      dealerId: MASTER_DEALERID
    }
    axios({
      method: 'GET',
      url: `${BASE_URI}/Offers`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {
      setDeals(response.data?.list)
      setHasMore(response.data?.list.length > 0)
      setLoading(false)
    }).catch( error => {
      if(axios.isCancel(error)) return
      setLoading(false)
    })
    return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber,sortDirection])

  return {loading, error, deals, hasMore}
}